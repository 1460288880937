<template>
  <div>
    <v-alert border="left" :type="type || 'error'">
      {{ text }}
    </v-alert>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    text() {
      return this.$route.params.text;
    },
  },
};
</script>

